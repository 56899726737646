import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, signal } from '@angular/core';
import { StationPreviewComponent } from '@app/components/station-preview/station-preview.component';
import { GeocodeResult, RouteResponse, SearchResult } from '@app/services/model/search';
import { Station } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { Router } from '@angular/router';
import { SearchService } from '@app/services/search.service';
import { Map, Marker, LatLng, Icon, polyline, LatLngExpression, marker, divIcon, icon, popup } from 'leaflet';
import { FormControl } from '@angular/forms';
import { AddressPreviewComponent } from '@app/components/address-preview/address-preview.component';

@Injectable({
  providedIn: 'root'
})
export class SearchControlService {

  overlayOpen = signal(false);
  recentSearches = signal<GeocodeResult[]>([]);
  searchResults = signal<SearchResult[]>([]); // Signal for search results
  selectedSearchResult = signal<SearchResult | null>(null); // Shared signal
  autoFillDestination = signal<string | null>(null); // Shared signal
  searchFormControl = new FormControl('');
  map: Map;
  private markers: Marker[] = [];
  navigationPlannerLayers: any[] = [];
  public STATION_LAYER_NAME = 'leaflet-station-pin';

  stationPreviewLayers: any[] = [];
  stationPreviewIds: string[] = [];

  isNavigationCollapsed = false;

  constructor(
    private router: Router,
    private searchService: SearchService,
    private stationService: StationService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
  ) {
    this.loadRecentSearches();
  }

  loadRecentSearches() {
    const storedSearches = localStorage.getItem('recentGeocodes');
    if (storedSearches) {
      this.recentSearches.set(JSON.parse(storedSearches));
    }
  }

  addToRecentSearch(geocodeResult: GeocodeResult) {
    const recentSearches = this.recentSearches();

    const updatedSearches = [geocodeResult, ...recentSearches].slice(0, 5); // Keep only the last 5 searches

    this.recentSearches.set(updatedSearches);

    localStorage.setItem('recentGeocodes', JSON.stringify(updatedSearches));

    this.addPinToMap(geocodeResult);
  }

  clearSelectedSearchResult() {
    this.selectedSearchResult.set(null); // Clear the selected search result
  }

  setMap(map: Map) {
    this.map = map;
  }

  addPinToMap(geocodeResult: GeocodeResult) {
    if (!this.map) {
      console.error("Map is not initialized");
      return;
    }

    const customPinIcon = new Icon({
      iconUrl: 'assets/map/icons/LocationMarker.svg',
      iconSize: [52, 52], // Adjust the size to match your SVG
      iconAnchor: [26, 52], // Anchor point in the icon (bottom center)
      popupAnchor: [0, -52], // Point from which the popup should open relative to the iconAnchor
    });

    const { latitude, longitude } = geocodeResult;
    const latLng = new LatLng(latitude, longitude);

    // Create a marker and add it to the map
    const marker = new Marker(latLng, { icon: customPinIcon }).addTo(this.map);

    // Create a popup with the address and bind it to the marker
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AddressPreviewComponent);
    const componentRef: ComponentRef<AddressPreviewComponent> = componentFactory.create(this.injector);

    componentRef.instance.address = geocodeResult;

    this.appRef.attachView(componentRef.hostView);

    const popupContent = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
    const addressPopup = popup({
      closeButton: false,
      minWidth: 370,
      className: `address-pin-overview`
    })
      .setLatLng(latLng)
      .setContent(popupContent)
      .openOn(this.map);
    marker.bindPopup(addressPopup).openPopup();
    this.markers.push(marker);

    // Optionally center the map on the new marker
    this.map.setView(latLng, 14); // Adjust the zoom level as needed
  }

  removeLocationMarkers() {
    this.markers = this.markers.filter(marker => {
      this.map.removeLayer(marker); // Remove marker from map if the name matches
      return false; // Remove marker from array
    });
  }

  performGeocode(searchResult: SearchResult) {
    if(searchResult.isStation) {
      this.router.navigate([`/station/${searchResult.placeId}`]);
    } else {
      this.searchService.geocode(searchResult.placeId, 'place').subscribe(geocodeResult => {
        this.addToRecentSearch(geocodeResult);

      });
    }
  }

  onSearchItemClick(search: SearchResult) {
    this.removeLocationMarkers();
    this.selectedSearchResult.set(search); // Update the selected search result signal
    this.overlayOpen.set(false);
    this.performGeocode(search);

    if (search?.address?.formattedAddress) {
      this.searchFormControl.setValue(search?.address?.formattedAddress)
    }
  }

  clearNavigationPlanner() {
    this.navigationPlannerLayers.forEach(layer => {
      this.map.removeLayer(layer);
    });
  }

  updateNavigationPlanner(route: RouteResponse): void {
    this.clearNavigationPlanner();
    this.disableStationPins();

    // NOTE: for some reason, there's longitude and latitude swapped in the response
    const coordinates = route.legs
      .flatMap(leg => leg.polyline.segment.coordinates
        .map(([lng, lat]) => [lat, lng])
      ) as LatLngExpression[];

    route.legs.forEach((leg, index) => {
      const first = index === 0;
      const last = index === route.legs.length - 1;

      if (first) {
        const startingMarker = marker(
          [leg.startLocation?.latLng?.latitude, leg.startLocation?.latLng?.longitude],
          {
            icon: divIcon({
              className: 'navigation-starting-location-marker',
              iconSize: [32, 32],
              iconAnchor: [16, 16],
            })
          }
        ).addTo(this.map);
        this.navigationPlannerLayers.push(startingMarker);
        this.map.setView([leg.startLocation?.latLng?.latitude, leg.startLocation?.latLng?.longitude], 12);
      } else {
        const waypointMarker = marker(
          [leg.startLocation.latLng.latitude, leg.startLocation.latLng.longitude],
          {
            icon: divIcon({
              className: 'navigation-ending-location-marker navigation-waypoint-location-marker',
              html: `<mat-icon class="material-symbols-outlined">directions</mat-icon>`,
              iconSize: [32, 32],
              iconAnchor: [32, 32],
            })
          }
        ).addTo(this.map);
        this.navigationPlannerLayers.push(waypointMarker);
      }

      if (last) {
        const endingPoint = leg?.endLocation?.latLng;
        if (endingPoint) {
          const endingMarker = marker(
            [leg?.endLocation?.latLng?.latitude, leg?.endLocation?.latLng?.longitude],
            {
              icon: divIcon({
                className: 'navigation-ending-location-marker',
                html: `<mat-icon class="material-symbols-outlined">distance</mat-icon>`,
                iconSize: [32, 32],
                iconAnchor: [32, 32],
              })
            }
          ).addTo(this.map);
          this.navigationPlannerLayers.push(endingMarker);
        }
      }
    });

    const navigation = polyline(coordinates, {
      weight: 8,
      color: '#0097e9',
    }).addTo(this.map);
    this.navigationPlannerLayers.push(navigation);
  }

  showNavigationPins(route: RouteResponse, onPinClick?: (legIndex: number, stationIndex: number) => void) {
    route.legs.forEach((leg, index) => {
      const stations = leg.stations;
      const zoom = this.map.getZoom();
      const count = stations?.length ?? 0;
      // Define default min and max radius values
      let minRadius = 20;
      let maxRadius = 35;

      // Adjust radius based on zoom level
      if (zoom < 8) {
        minRadius = 11;
        maxRadius = 20;
      }

      // Calculate the radius
      let radius = Math.min(maxRadius, minRadius + Math.log(count) * 2);

      // Further adjust radius for count === 1 based on zoom level
      if (count === 1) {
        radius = Math.min(maxRadius, minRadius + zoom / 2);
      }

      // TODO: implement the power levels
      const iconUrl = 'assets/map/icons/MarkerPlus.svg';
      const stationMarkerIcon = icon({
        iconUrl: iconUrl,
        iconSize: [radius * 2, radius * 2],
        iconAnchor: [radius, radius],
      });
      stations.forEach((station, stationIndex) => {
        const stationMarker = marker(
          {
            lat: station.location?.latitude,
            lng: station.location?.longitude
          },
          { icon: stationMarkerIcon }
        ).addTo(this.map);
        (stationMarker as any)._layerName = 'navigation-station-pin';
        (stationMarker as any)._legIndex = index;
        (stationMarker as any)._stationIndex = stationIndex;

        let hoverTimer = null;
        let overTimer = null;
        stationMarker.on('mouseover', (e) => {
          hoverTimer = setTimeout(() => {
            if (this.stationPreviewIds.includes(String(station.id))) {
              this.stationPreviewLayers[this.stationPreviewIds.indexOf(String(station.id))]?.openOn(this.map);
            } else {
              this.stationService.getStationById([station.id]).subscribe(results => {
                const stationPopup = this.openStationPreview(results.data.stationById[0], e.latlng, () => {
                  const legIndex = (e.target as any)._legIndex;
                  const stationIndex = (e.target as any)._stationIndex;
                  onPinClick?.(legIndex, stationIndex);
                });

                const popupElement = stationPopup.getElement();
                popupElement.addEventListener('mouseover', () => {
                  clearTimeout(overTimer);
                  stationPopup.openOn(this.map);
                });
                popupElement.addEventListener('mouseout', () => {
                  clearTimeout(hoverTimer);

                  overTimer = setTimeout(() => {
                    this.map.closePopup();
                  }, 300);
                });
              });
            }
          }, 300);
        });

        stationMarker.on('mouseout', () => {
          clearTimeout(hoverTimer);

          overTimer = setTimeout(() => {
            this.map.closePopup();
          }, 300);
        });

        this.navigationPlannerLayers.push(stationMarker);
      });
    });
  }

  private openStationPreview(station: Station, latlng: LatLng, toggleStation: () => void) {
    // Use Angular's ComponentFactoryResolver to dynamically create the component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(StationPreviewComponent);
    const componentRef: ComponentRef<StationPreviewComponent> = componentFactory.create(this.injector);

    componentRef.instance.station = station;
    componentRef.instance.onToggleStation = toggleStation;

    this.appRef.attachView(componentRef.hostView);

    const popupContent = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
    const stationPopup = popup({
      keepInView: true,
      autoPan: true,
      autoPanPadding: window.innerWidth <= 768 ? [5, 5] : [100, 50],
      closeButton: false,
      minWidth: 370,
      className: `station-pin-overview station-pin-overview-${station.id}`
    })
      .setLatLng(latlng)
      .setContent(popupContent)
      .openOn(this.map);
    this.stationPreviewLayers.push(stationPopup);
    this.stationPreviewIds.push(String(station.id));
    return stationPopup;
  }

  disableStationPins() {
    this.map?.eachLayer((layer) => {
      if ((layer as any)._layerName === this.STATION_LAYER_NAME) {
        (layer as any).setOpacity(0);
      }
    });
  }

  enableStationPins() {
    this.map?.eachLayer((layer) => {
      if ((layer as any)._layerName === this.STATION_LAYER_NAME) {
        (layer as any).setOpacity(1);
      }
    });
  }

  getMapCenterCoords() {
    return {
      latitude: this.map?.getCenter().lat,
      longitude: this.map?.getCenter().lng
    };
  }
}
