import { Component, effect, inject, OnInit } from '@angular/core';
import { MatDivider } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { SearchControlService } from "@app/pages/map/controls/services/search-control.service";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";
import { SearchService } from '@app/services/search.service';
import {GeocodeResult, SearchResult} from '@app/services/model/search';
import { NgIf } from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-search-overlay',
  standalone: true,
  imports: [
    MatDivider,
    MatListModule,
    MatIcon,
    MatIconButton,
    NgIf
  ],
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.scss']
})
export class SearchOverlayComponent {

  searchControlService = inject(SearchControlService);
  searchService = inject(SearchService);
  recentSearches = this.searchControlService.recentSearches;
  searchResults = this.searchControlService.searchResults; // Get the search results signal
  selectedSearchResult = this.searchControlService.selectedSearchResult;

  constructor(private router: Router) {
    // Use an effect to react to changes in the selectedSearchResult signal
/*    effect(() => {
      const searchResult = this.selectedSearchResult();
      if (searchResult) {
        this.performGeocode(searchResult);
      }
    });*/
  }

  onRecentClick(search: GeocodeResult) {
    this.searchControlService.removeLocationMarkers();
    this.searchControlService.addToRecentSearch(search);
    this.searchControlService.overlayOpen.set(false);
  }

  onSearchItemClick(search: SearchResult) {
    this.searchControlService.onSearchItemClick(search);
  }
}
