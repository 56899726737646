import { Routes } from '@angular/router';
import { MapComponent } from '@app/pages/map/map.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { MainComponent } from './layout/main/main.component';
import { LegalComponent } from './layout/legal/legal.component';
import { StationDetailsComponent } from './pages/station-details/station-details.component';
import { StationImageComponent } from './pages/station-image/station-image.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NavigationComponent } from './pages/navigation/navigation.component';
import { AboutComponent } from './pages/about/about.component';
import { AddedStationsComponent } from './pages/profile/added-stations/added-stations.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { EditedStationsComponent } from './pages/profile/edited-stations/edited-stations.component';
import { ImagesComponent } from './pages/profile/images/images.component';
import { RatingsComponent } from './pages/profile/ratings/ratings.component';
import { TopbarComponent } from './layout/topbar/topbar.component';
import { AccountComponent } from './layout/account/account.component';
import { PersonalInformationComponent } from './pages/account/personal-information/personal-information.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { VehiclesComponent } from './pages/account/vehicles/vehicles.component';
import { authGuard } from './auth.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RewardsComponent } from './pages/profile/rewards/rewards.component';
import { AssignRewardComponent } from './pages/assign-reward/assign-reward.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    title: 'GitCharger | EV Charging Station Map | A smarter way to charge!',
    children: [
      {
        path: '',
        component: MapComponent,
        children: [
          {
            path: 'station/:stationId',
            component: StationDetailsComponent,
            children: [
              {
                path: 'image/:image',
                title: 'Station Image',
                component: StationImageComponent,
              },
            ],
          },
          {
            path: 'navigation',
            title: 'GitCharger | Navigation',
            component: NavigationComponent,
          },
        ],
      },
      {
        path: '',
        component: TopbarComponent,
        children: [
          {
            path: 'profile/:userId',
            canActivate: [authGuard],
            component: ProfileComponent,
            children: [
              {
                path: '',
                title: 'GitCharger | Rewards',
                component: RewardsComponent,
              },
              {
                path: 'added-stations',
                title: 'GitCharger | Added Stations',
                component: AddedStationsComponent,
              },
              {
                path: 'edited-stations',
                title: 'GitCharger | Edited Stations',
                component: EditedStationsComponent,
              },
              {
                path: 'images',
                title: 'GitCharger | Images',
                component: ImagesComponent,
              },
              {
                path: 'ratings',
                title: 'GitCharger | Ratings',
                component: RatingsComponent,
              },
            ],
          },
          {
            path: 'account',
            title: 'GitCharger | My Account',
            canActivate: [authGuard],
            component: AccountComponent,
            children: [
              {
                path: '',
                title: 'GitCharger | Personal Information',
                component: PersonalInformationComponent,
              },
              {
                path: 'change-password',
                title: 'GitCharger | Change Password',
                component: ChangePasswordComponent,
              },
              {
                path: 'vehicles',
                title: 'GitCharger | My Vehicles',
                component: VehiclesComponent,
              },
            ],
          },
          {
            path: 'event/:eventId',
            title: 'GitCharger | Event',
            component: AssignRewardComponent,
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: LegalComponent,
    children: [
      {
        path: 'privacy',
        title: 'GitCharger | Privacy Policy',
        component: PrivacyComponent,
      },
      {
        path: 'terms',
        title: 'GitCharger | Terms of Use',
        component: TermsComponent,
      },
      {
        path: 'contact',
        title: 'GitCharger | Contact Us',
        component: ContactComponent,
      },
      {
        path: 'about',
        title: 'GitCharger | About Us',
        component: AboutComponent,
      },
      { path: '**', title: "GitCharger | Page not found", component: PageNotFoundComponent },
    ],
  },
];
